<template>
  <div class="console">
    <form>
      <textarea
        autofocus
        ref="textarea"
        v-model="inputString"
      >
			</textarea>
    </form>

    <!-- <p>https://youtu.be/ZGCz1HIHuSs?t=45| yellow: 6jJkdRaa04g |  https://www.youtube.com/watch?v=ViP-Z26udYw&t=1687s</p> -->

    <!-- <input type="text" value="!rq iDO9J_3OVJ0,BZP1rYjoBgI,krlSC1Qp6_c,9SnjPZaFd7Y,kIDWgqDBNXA,zBY0HaX4YCo,i6sTatKAXt0,f9sg_HhSa-Y" /> -->
  </div>
</template>

<script>
  import { mapGetters } from "vuex";

  export default {
    data() {
      return {
        inputString: "", //'!rq https://www.youtube.com/watch?v=mgEixhE3Oms'
      };
    },
    computed: {
      ...mapGetters({
        tasksLibrary: "tasks/getTasksLibrary",
      }),
      commands() {
        return this.tasksLibrary.filter((item) => item.commands.length);
      },
    },
    mounted() {
      this.$refs.textarea.focus();

      this.$refs.textarea.addEventListener("keypress", (event) => {
        if (event.which === 13 && !event.shiftKey) {
          event.preventDefault();
          this.matchCommand(); // duplicate of code from chatbot
          return false;
        }
      });
    },
    methods: {
      matchCommand() {
        const userCmd = this.inputString.split(" ")[0];

        // Just replicated the logic from chatbot
        const commandObj = {
          name: userCmd,
          user: {
            username: "System",
            roles: {
              broadcaster: true,
              administrator: true,
              moderator: true,
              subscriber: true,
              vip: true,
            },
          },
        };
        // const arg = this.inputString.split(' ')[1];
        const arg =
          this.inputString.indexOf(" ") !== -1
            ? this.inputString.slice(this.inputString.indexOf(" ")).trim()
            : "";

        // THIS IS REPEATED IN CHATBOT.jS - MERGE SOMEHOW
        const task = this.tasksLibrary
          .filter((item) => item.commands.length)
          .find((task) =>
            task.commands.some((item) => {
              // For commands with a role suffix. Eg ['!m:administrator', '!a:vip']
              const csplit = item.toLowerCase().split(":");
              const command = csplit[0];
              const role = csplit[1];
              const con =
                command === (commandObj.name && commandObj.name.toLowerCase()) &&
                (!role ||
                  (role &&
                    (commandObj.user.roles[role] ||
                      commandObj.user.roles.broadcaster ||
                      commandObj.user.roles.administrator ||
                      commandObj.user.roles.moderator)));
              return con;
            })
          );

        commandObj.arg = arg;

        // AUTHORIZE IT
        if (task) this.authorizeCommand(task, commandObj);

        // Clear console.
        this.inputString = "";
      },

      //************************************************************
      // 05. AUTHORIZE COMMAND (from chatbot)
      //************************************************************

      authorizeCommand(taskObj, commandObj) {
        // PREPARE TASKS
        this.$store.dispatch("tasks/runTasksRemotely", [
          {
            uuid: taskObj.uuid,
            author: commandObj.user.username,
            data: {
              arg: commandObj.arg, // Remember: arg is just a dynamic string. Separate spaces with split.
            },
            silent: true,
          },
        ]);
      },
    },
  };
</script>

<style lang="scss" scoped>
  //--------------------------------------------------------
  // CONSOLE
  //--------------------------------------------------------

  .console {
    position: relative;
    display: flex;
    flex-direction: column;
    // position: absolute;
    // top: 360px;
    // top: 450px;
    // left: 20px;
    z-index: 2;
  }

  p {
    color: darken(white, 10%);
    padding: rem-calc(10 0);
    font-size: rem-calc(12);
    line-height: 1;
    margin-bottom: 0;
  }

  .console form {
    width: 100%;
  }

  .console textarea {
    width: 100%;
    height: rem-calc(50);
    padding: rem-calc(15 15);
    background: rgba(black, 0.8);
    border: 0;
    font-family: "Andale Mono";
    font-size: rem-calc(16);
    line-height: 1;
    font-weight: 700;
    color: $primary; //#28fe14;
    outline: none;
    box-shadow: none;
  }

  .console button {
    display: none;
  }
</style>